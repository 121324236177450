
import { useUiStore } from '@/stores/ui'
import { WHITELISTED_PATHS } from '@/constants'

export default {
  name: 'BannerBonusBank',
  setup() {
    const uiStore = useUiStore()
    return {
      uiStore,
    }
  },
  mounted() {
    if (this.$device.isAndroid || this.$device.isIos) {
      this.uiStore.showBannerBonusBank = this.pathMatchesWhitelist()
    } else {
      this.uiStore.showBannerBonusBank = false
    }
  },
  methods: {
    pathMatchesWhitelist() {
      return WHITELISTED_PATHS.some(path => {
        const escapedPath = path.replace(/[.+?^${}()|[\]\\]/g, '\\$&')

        const pathRegex = new RegExp(`^${escapedPath.replace(/\*/g, '.*')}$`)
        return pathRegex.test(this.$route.path)
      })
    },
    openApp() {
      this.$openNativeApp()
    },
    closePopup() {
      this.uiStore.showBannerBonusBank = false
    },
  },
}
